import { useEffect } from "react";
import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import PercentIcon from "@mui/icons-material/Percent";
import { useSelector, useDispatch } from "react-redux";
import {
  setDiscPer,
  setDiscAmt,
  setTaxPer,
  setOther,
  setDiscAmtByPer,
  setTaxAmt,
  setTotal,
  setNetTotal,
} from "../slice/discAndTaxSliceReturnB2C";

const Calculation = () => {
  const dispatch = useDispatch();

  // Selecting data from Redux state
  const tableDataReturnB2C = useSelector((state) => state.tableDataReturnB2C);
  const discPer = useSelector((state) => state.discAndTaxReturnB2C.discPer);
  const discAmtByPer = useSelector((state) => state.discAndTaxReturnB2C.discAmtByPer);
  const discAmt = useSelector((state) => state.discAndTaxReturnB2C.discAmt);
  const taxPer = useSelector((state) => state.discAndTaxReturnB2C.taxPer);
  const taxAmt = useSelector((state) => state.discAndTaxReturnB2C.taxAmt);
  const other = useSelector((state) => state.discAndTaxReturnB2C.other);
  const total = useSelector((state) => state.discAndTaxReturnB2C.total);
  const netTotal = useSelector((state) => state.discAndTaxReturnB2C.netTotal);
  const decimalCount = useSelector((state) => state.common.decimalCount);

  // Calculate the total
  const totalCalc = tableDataReturnB2C.reduce((acc, cur) => {
    return acc + cur.total;
  }, 0);

  // Use an effect to update total and netTotal when dependencies change
  useEffect(() => {
    dispatch(setTotal(totalCalc));
    dispatch(setDiscAmtByPer((discPer / 100) * total));

    // Calculate the netTotal
    const netTotalCalc =
      totalCalc +
      (taxPer / 100) * total -
      (discPer / 100) * total -
      discAmt +
      other;
    dispatch(setNetTotal(netTotalCalc));
  }, [totalCalc, discPer, taxPer, discAmt, other, tableDataReturnB2C, dispatch, total]);
  useEffect(() => {
    const discounted = (discPer / 100) * total;
    dispatch(setDiscAmtByPer(discounted));

    const calculatedTaxAmt = (taxPer / 100) * total;

    // Dispatch the tax amount
    dispatch(setTaxAmt(calculatedTaxAmt));
  }, [discPer, total, taxPer, dispatch]);

  // Handle changes in discount percentage
  const handleDiscountPerc = (e) => {
    dispatch(setDiscPer(e.target.value));

    // Calculate the discount amount by percentage
    // const discounted = (e.target.value / 100) * total;

    // // Dispatch the discount amount by percentage
    // dispatch(setDiscAmtByPer(discounted));
  };

  // Handle changes in tax percentage
  const handleTaxPerc = (e) => {
    dispatch(setTaxPer(e.target.value));

    // Calculate the tax amount
    // const calculatedTaxAmt = (e.target.value / 100) * total;

    // // Dispatch the tax amount
    // dispatch(setTaxAmt(calculatedTaxAmt));
  };

  const handleOther = (e) => {
    // const otherChrg
    dispatch(setOther(parseInt(e.target.value)));
    // const newNetTotal = parseFloat(e.target.value) + netTotal;
    // dispatch(setNetTotal(newNetTotal));
  };
  const handleDiscAmount = (e) => {
    dispatch(setDiscAmt(e.target.value));
  };
  return (
    <Box component={Paper} elevation={3} sx={{ p: 1.5 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={4} md={2}>
              <TextField
                label="Discount %"
                type="number"
                fullWidth
                size="small"
                value={discPer}
                onChange={handleDiscountPerc}
                inputProps={{
                  min: 0,
                  style: { textAlign: "end", fontWeight: "bold" },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentIcon sx={{ fontSize: 16 }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                type="number"
                label="Discount % amt"
                fullWidth
                size="small"
                value={
                  typeof discAmtByPer === "number" ? discAmtByPer.toFixed(3) : 0
                }
                inputProps={{
                  min: 0,
                  readOnly: true,
                  style: { textAlign: "center", fontWeight: "bold" },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                type="number"
                label="Tax %"
                fullWidth
                size="small"
                onChange={handleTaxPerc}
                value={taxPer}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PercentIcon sx={{ fontSize: 16 }} />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  min: 0,
                  style: { textAlign: "end", fontWeight: "bold" },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                type="number"
                label="Tax Amount"
                fullWidth
                size="small"
                value={taxAmt.toFixed(3)}
                inputProps={{
                  min: 0,
                  style: { textAlign: "center", fontWeight: "bold" },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                type="number"
                label="Discount Amount"
                fullWidth
                size="small"
                onChange={(e) => {
                  handleDiscAmount(e);
                }}
                value={discAmt}
                inputProps={{
                  min: 0,
                  style: { textAlign: "end", fontWeight: "bold" },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </Grid>
            <Grid item xs={4} md={2}>
              <TextField
                type="number"
                label="Other charges"
                fullWidth
                size="small"
                onChange={handleOther}
                value={parseInt(other)}
                inputProps={{
                  min: 0,
                  style: { textAlign: "end", fontWeight: "bold" },
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} alignContent={"flex-end"}>
          <Grid container justifyContent={"flex-end"}>
            <Grid item xs={4} md={3}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4" gutterBottom>
                  Subtotal :
                </Typography>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ textAlign: "right" }}
                >
                  {total.toFixed(decimalCount)}
                </Typography>
              </Stack>
            </Grid>
            <Grid container justifyContent={"flex-end"}>
              <Grid item xs={4} md={3}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h4" ml={-0.5}>
                    Net Total :
                  </Typography>
                  <Typography variant="h4" sx={{ textAlign: "right" }}>
                    {netTotal.toFixed(decimalCount)}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Calculation;
