import axios from "axios";
import { redirectUrl } from "config";
import { baseUrl } from "config";
import { useSnackBar } from "context/snackbarContext";
import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

function Print() {
  const location = useLocation();
  const {
    data,
    tableData,
    qrData,
    printType,
    typ,
    isInvoiceData,
    invoiceOnly,
    kotTableData,
  } = location.state;
  console.log(data, "kotTableDataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
  console.log(tableData, "kotTableDataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
  console.log(qrData, "kotTableDataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
  console.log(printType, "kotTableDataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
  // console.log(tableData, "tableData");
  // console.log(qrData, "qrData");
  const navigate = useNavigate();
  const { showSnackbar } = useSnackBar();

  useEffect(() => {
    const renderComponent = () => {
      switch (printType) {
        case "LazerA4PrintB2b_TAXGST":
          if (typ === "SB" || typ === "SR" || typ === "OF") {
            return navigate("/printer/a4", {
              state: {
                data: data,
                tableData: tableData,
                qrData: qrData,
                typ: typ,
              },
            });
          } else {
            return navigate("/printer/purchaseA4", {
              state: {
                data: data,
                tableData: tableData,
                qrData: qrData,
                typ: typ,
              },
            });
          }

        case "ThermalPrintB2b_TAXGST":
          if (typ === "SB" || typ === "SR") {
            return navigate("/printer/thermalPrint", {
              state: {
                data: data,
                tableData: tableData,
                qrData: qrData,
                typ: typ,
              },
            });
          } else {
            // console.log("wokkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
            // console.log("data",data);
            // console.log("tableData",tableData);
            // console.log("qrData",qrData);
            // console.log("typ",typ);

            return navigate("/printer/purchaseThermal", {
              state: {
                data: data,
                tableData: tableData,
                qrData: qrData,
                typ: typ,
              },
            });
          }
        case "LazerA4PrintB2b_TAXVAT":
          return navigate("/printer/lazerA4PrintTaxVat", {
            state: {
              data: data,
              tableData: tableData,
              qrData: qrData,
              typ: typ,
            },
          });
        case "ThermalPrintB2b_TAXVAT":
          return navigate("/printer/ThermalPrintTaxVat", {
            state: {
              data: data,
              tableData: tableData,
              qrData: qrData,
              typ: typ,
            },
          });
        case "THERMAL_3INCH_DIRECT":
          console.log("workeddddddddddddddddd", invoiceOnly);

          navigate(-1);
          return axios({
            method: "post",
            url: baseUrl + "/print",
            data: {
              master: data,
              table: tableData,
              typ: "THERMAL_3INCH_DIRECT",
              isInvoiceData,
              invoiceOnly,
              qrData: qrData,
              kotTableData,
            },
            withCredentials: true,
          })
            .then((res) => {})
            .catch((err) => {
              showSnackbar("error", err?.response?.data?.message);

              if (err.response.status === 403 || err.response.status === 401) {
                redirectUrl();
              }
            });
        case "THERMAL_3INCH_DIRECT_VAT":
          console.log("workeddddddddddddddddd", invoiceOnly);

          navigate(-1);
          return axios({
            method: "post",
            url: baseUrl + "/print",
            data: {
              master: data,
              table: tableData,
              typ: "THERMAL_3INCH_DIRECT_VAT",
              isInvoiceData,
              invoiceOnly,
              qrData: qrData,
              kotTableData,
            },
            withCredentials: true,
          })
            .then((res) => {})
            .catch((err) => {
              showSnackbar("error", err?.response?.data?.message);

              if (err.response.status === 403 || err.response.status === 401) {
                redirectUrl();
              }
            });
        default: {
          navigate(-1);
          return showSnackbar("error", `Please select printer`);
        }
      }
    };
    renderComponent();
  }, [data, navigate, printType, qrData, tableData, typ]);

  return <div> </div>;
}

export default Print;
