import SalesLayOut from "views/Sales-Sections/SalesLayOut";
import SalesReturn from "views/Sales-Sections/salesReturn/SalesReturn";
import SalesReturnB2C from "views/Sales-Sections/SalesRetrunB2C/SalesRetrunB2C";
import SalesPos from "views/Sales-Sections/salesPos/SalesPos";
import SalesPosB2C from "views/Sales-Sections/salesPosB2C/SalesPosB2C";
import OrderForm from "views/Sales-Sections/orderForm/OrderForm";

const SalesRoute = {
  path: "sales",
  element: <SalesLayOut />,
  children: [
    {
      path: "salesPos",
      element: <SalesPos typp="salesBill" />,
    },
    {
      path: "salesPosb2c",
      element: <SalesPosB2C />,
    },
    {
      path: "salesReturn",
      element: <SalesReturn typp="salesReturn" />,
    },
    {
      path: "salesReturnB2C",
      element: <SalesReturnB2C typp="salesReturnB2C" />,
    },
    {
      path: "orderForm",
      element: <OrderForm typp="orderForm" />,
    },

    // {
    //     path: 'print',
    //     element: <Print />
    // }
  ],
};
export default SalesRoute;
